export enum EnumSection {
  ComingSoon = -9,
  PrivacyPolicy = -3,
  TermsAndConditions = -2,
  Home = -1,
  About = 0,
  Mission,
  News,
  Pricing,
  Team,
  Resources,
  Contact,
  Story,
}
